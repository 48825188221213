import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import heroStyles from '../components/hero.module.css'
import Img from 'gatsby-image'

class ServicesIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [headerImage] = get(this, 'props.data.allContentfulAsset.edges')

    return (
      <Layout location={this.props.location} pageName={'services'} >
        <div style={{ background: '#fff' }}>
          <Helmet title={'Services | ' + siteTitle} defer={false}/>
          <div className={heroStyles.hero}>
            <Img className={heroStyles.heroImage} alt={headerImage.node.title} fluid={headerImage.node.fluid} />
          </div>
          <div className="wrapper">
            <h1 className="section-headline">Services</h1>
            <h2 className="section-list">Back-end</h2>
            <p>I can help you build APIs, microservices, or entire web applications/sites. I work with Node.js, Java, Spring Boot and others.<br/>Back-end services include: Redis, MongoDB, MySQL, DynamoDB, Elasticsearch, and Message Queues.</p>

            <h2 className="section-list">Front-end</h2>
            <p>I work with straight HTML, CSS, LESS, SASS, AngularJS, Angular, React, ES6+, jQuery, build tools and transpilers such as Babel, Gulp and others.</p>

            <h2 className="section-list">DevOps</h2>
            <p>I work with CI tools Jenkins(X), CircleCI; build tools such as Maven, Gradle, Gulp, and Grunt; repositories such as Nexus for Docker, NPM and Java images; code quality tools such as Codacy and SonarQube/SonarCloud and manual code review systems; deployment tools such as Terraform, AWS CloudFormation, Puppet and Ansible; container systems Docker and Kubernetes.</p>

            <h2 className="section-list">Cloud Services</h2>
            <p>I work with EC2, DynamoDB, Aurora, ElastiCache, S3,ECR, ECS, Fargate and CloudFormation.</p>
          </div>
          <div className="wrapper">
            <h1 className="section-headline">Contact Me</h1>
            <form method="post" action="https://formspree.io/xdowzvjn">
              <label>
                Name
                <input type="text" name="name" id="name" />
              </label>
              <label>
                Email
                <input type="email" name="email" id="email" />
              </label>
              <label>
                Company/Site
                <input type="company_site" name="company_site" id="company_site" />
              </label>
              <label>
                Project Description
                <textarea name="message" id="message" rows="5" />
              </label>
              <label>
                Budget
                <select name="budget" id="budget">
                  <option value="10k">0 - 10k</option>
                  <option value="25k">11k - 25k</option>
                  <option value="50k">26k - 50k</option>
                  <option value="50k+">50k+</option>
                </select>
              </label>
              <button type="submit">Send</button>
              <input type="reset" value="Clear" />
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ServicesIndex

export const pageQuery = graphql`
  query ServicesQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulAsset(filter: {contentful_id: {eq: "5V35t6bEu4cP0KwHLgkTut"}}) {
      edges {
        node {
          title
          fluid(maxWidth: 1180, background: "rgb:000000") {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
      }
    }
  }
`
